import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import { enGB } from 'date-fns/locale';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import KeycloakProvider from 'react-lib/dist/keycloak-es';
import store from './store';
import './index.less';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { MediaContextProvider, mediaStyles } from './mediaStyles';
import LoadingComponent from './LoadingComponent';
import { keycloakConfig } from './keycloak';

registerLocale('en-GB', enGB);

const queryCache = new QueryCache();
export const queryClient = new QueryClient({
  cache: queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        const { response } = error ?? {};
        if (response?.status === 403) {
          return false;
        }
        if (failureCount < 3) {
          return true;
        }
        return false;
      },
      getPreviousPageParam: firstPage =>
        firstPage.pageable.pageNumber > 0 ? firstPage.pageable.pageNumber - 1 : undefined,

      getNextPageParam: lastPage => (!lastPage.last ? lastPage.pageable.pageNumber + 1 : undefined),
    },
  },
});

const rootElement = document.getElementById('root');


serviceWorkerRegistration.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

ReactDOM.render(
  <>
    <style>{mediaStyles}</style>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <MediaContextProvider>
          <KeycloakProvider keycloakConfig={keycloakConfig} LoadingComponent={LoadingComponent}>
            <App />
          </KeycloakProvider>
        </MediaContextProvider>
      </QueryClientProvider>
    </Provider>
  </>,
  rootElement
);
