import React, {Component} from 'react';
import {Button, Header, Icon, Modal} from 'semantic-ui-react';

class ErrorBoundary extends Component {

  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  render() {
    const navigateToHome = () => {
      window.location.href = '/'
    };

    const { eventId } = this.state;
    return (
      <Modal
        size="tiny"
        open
        onClose={() => {
          navigateToHome()
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <Header as="h1">Hrmm... something broke.</Header>
            <Icon name="frown outline" size="massive" disabled />
            <p>Please let us know what happened.</p>
            <Button
              primary
              onClick={e => {
                e.preventDefault();
                navigateToHome()
              }}
            >
              Send Feedback
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default ErrorBoundary;
